import axios from 'axios'

const instance = axios.create({
  timeout: 10000,
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'api/'
      : `${process.env.REACT_APP_API}/api/`,
})

instance.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

/**
 * 请求响应器
 */

instance.interceptors.request.use(
  (config) => {
    config.headers['token'] = ''
    return config
  },
  (error) => Promise.reject(error)
)

/**
 * 请求拦截器
 */

instance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data)
  },
  (error) => Promise.reject(error)
)

/**
 * get请求封装
 */

export const get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',
      url,
      params,
      ...config,
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * post请求的封装
 */
export const post = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url,
      data,
      ...config,
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
