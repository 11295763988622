import React from 'react'
import { useLocation } from 'react-router-dom'
import './mobile.scss'
import './index.scss'

export default () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  // const device = params.get('device')
  const invite_number = params.get('invite_number')
  const description = params.get('description')
  // const from = params.get('from')
  
  return (<div className="invitation-container">

  <div className="titImg">
    <img alt='invite' src={require('../../assets/invite.png')} />
  </div>

  <p className="tipsText">亲们，下面的数字就是我分享的晓黑板教师邀请码，需要的亲赶快拿去用吧
    {Boolean(description) ? '，请尽快在有效期内使用吧~~' : '。'}
  </p>

  <div className="inviteShare">
      <p>教师邀请码</p>
      <h2>{invite_number}</h2>
      { Boolean(description) && <h3>{description}</h3>}
  </div>

  <a href="http://a.app.qq.com/o/simple.jsp?pkgname=org.xinkb.blackboard.android&g_f=991653" className="btn">下载晓黑板</a>

  <div className="mdxhb">
    <span>为老师减负，帮孩子养成好习惯</span>
    <a href="https://mp.weixin.qq.com/s/iMs4UXIGHfpqlyUEQVsa3w">
      秒懂晓黑板
      <img alt='arrow-right-icon' src={require('../../assets/arrow-right.png')} />
    </a>
  </div>
</div>
)
}
