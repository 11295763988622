import React, { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import animate from '@minax/animate-scroll'
import './index.scss'
import Info from './Info'
import Line from '../../components/Line'
import Footer from '../Footer'

const featuresData = [
  {
    id: 'principal',
    avatar: require('../../assets/features_school_master.png'),
    name: '潘倩青',
    stage: '校长',
    address: '上海市静安区景凤路小学',
    joinTime: '2016.3.27',
    classNumber: 1,
    memberNumber: 41,
    messageNumber: 101,
    desc:
      '过去，当遇到紧急事件需要及时传达时，我只能选择开会，但是当我临时外出或其他老师无法出席会议的时候，或多或少都会造成要求传达不到位，同时，还降低了工作效率，影响老师的正常工作安排。\n 后来，微信出现了，确实方便了不少，但是每发一条信息，还是需要花大量的时间去了解老师看过与否，没看过的还要去一一提醒，反而让简单的事情变复杂了。\n 现在，学校都开始使用晓黑板了。每次给老师发通知后，只要点击查看“晓眼睛”，就可以清楚地了解老师们的反馈情况。未读消息的老师，用“晓叮当”提醒他查看消息；而对于看过消息有疑惑的老师，则发送“私聊”进行单独沟通，不仅节省了每个人的时间，还提高了我的工作效率，学校沟通也更加顺畅了!',
  },
  {
    id: 'teacher',
    avatar: require('../../assets/features_teacher.png'),
    name: '王靖',
    stage: '教师',
    address: '上海行知外国语学校',
    joinTime: '2015.9.28',
    classNumber: 2,
    memberNumber: 71,
    messageNumber: 4426,
    desc:
      '作为英语老师，每次给学生们布置口头作业，担心学生不会认真地完成，所以要花大量的时间去检查孩子们的完成情况，要么占用课堂时间，要么占用下课时间，即使这样，依旧有一部分的学生需要“返工”，占用了我大部分时间，非常影响工作效率，浪费时间。\n 后来，我把口头作业“搬”到了晓黑板上，我只要轻松发布一个语音作业，学生们就可以回家把自己的语音发到晓讨论里，大家可以互听，互评。而正是因为这样，学生们的语音作业比从此都变得格外认真，大家的口语也有明显的进步。而我呢，则可以利用零碎的时间来检查孩子们的作业，同时，晓黑板的每一段语音都可以暂停，倒退和快进，方便反复听，我也可以对孩子们的语音作业做出纠正和示范，孩子们则可以通过暂停功能来跟读老师的示范语音。',
  },
  {
    id: 'student',
    avatar: require('../../assets/features_student.png'),
    name: '薛智轩',
    stage: '学生',
    address: '位育实验学校',
    joinTime: '2015.8.5',
    classNumber: 0,
    joinClassNumber: 2,
    schoolNumber: 0,
    memberNumber: 0,
    messageNumber: 3285,
    desc:
      '有时候我会忘记今天布置了什么作业，又不敢问老师。不过后来我就不担心了，老师会把作业发到晓黑板里，再也不怕忘记作业了。',
  },
]

export default () => {
  const elsRef = useRef([])
  const { type } = useParams()
  useEffect(() => {
    const findIndex = featuresData.findIndex((i) => i.id === type)
    if (findIndex > -1 && elsRef.current[findIndex]) {
      const { top } = elsRef.current[findIndex].getBoundingClientRect()
      const scrollY = window.scrollY
      setTimeout(() => {
        animate(
          scrollY,
          scrollY + top - 84,
          (px) => {
            window.scrollTo(0, px)
          },
          { spendTime: 600, animationFunc: 'easeInOutExpo' }
        )
      }, 100)
    }
  }, [type])
  return (
    <div className="features">
      <Line />
      {featuresData.map((item, index) => {
        return (
          <Info
            key={index}
            ref={(el) => {
              elsRef.current.push(el)
            }}
            infoData={item}
            style={{ marginTop: index === 0 ? '80px' : '' }}
          />
        )
      })}
      <div className="bottom-placeholder"></div>
      <Footer />
    </div>
  )
}
