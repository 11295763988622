import React, { useEffect } from 'react'
import trace from '../../utils/bi'

const JumpDingtalk = () => {
  document.title='';

  useEffect(() => {
    document.title='';
    try {
      // let tessturl = 'https://xiaoheiban.cn/#/jump-dingtalk?targetUrl=https://h5.xueersi.com/6303b79207bda04b00cafdcc.html?userId=112&env=online&name=haha'
      let data = window.location.href.split('?');
      let content = data.length < 2 ? [] : data.splice(1)
      let params = [];
      for(let i = 0; i < content.length; i++) {
        let item = content[i]?.split('&')
        params = [...params, ...item]
      }
      let obj = {};
      for(let i = 0; i< params.length; i++) {
        let [k,v] = params[i].split('=')
        obj[k] = v;
      }
      if (obj.env === 'UAT') obj.env = 'development';
      else if (obj.env === 'ONLINE') obj.env = 'production';
      else if (obj.env === 'PRE') obj.env = 'staging';
      console.log('userId:::::::bgm', obj)
      
      const _trace = async () => {
        const { userId, env, popupId, popupName, os } = obj;
        if (!!os && popupId) {
          await trace({
            userId: userId,
            desc: '弹屏-点击图片下方按钮-跳转页面-关闭弹屏',
            pageId: 30026,
            typeId: '30026.8',
            path: '/ding',
            params: {
              popupId,
              popupName: decodeURI(popupName)
            },
            os: os || ''
          }, env)
        }
        window.location.href = obj.targetUrl || 'https://oa.dingtalk.com/register_h5_new.htm?source=xiaoheiban#/';
      }
      _trace()
    } catch(e) {
      console.log(e)
    }
  }, [])

  return (
    <div>
      正在跳转...
    </div>
  )
}

export default JumpDingtalk
