import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import eventBus from "../../utils/events";
import './index.scss'
import './index-media.scss'

const Navbar = (props) => {
  const { transparentBackground, topDistance } = props
  const { pathname } = useLocation()
  const [showMenu, setShowMenu] = useState(false)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (pathname === '/') setIndex(0)
    if (pathname === '/features') setIndex(1)
    if (pathname === '/about') setIndex(2)
    if (pathname === '/download') setIndex(3)
    if (pathname === '/feedback') setIndex(4)
    if (pathname === '/provacy') setIndex(5)
  }, [pathname])

  const handleClick = () => {
    setShowMenu(!showMenu)
  }
  return (
    <>
      <div
        className="mobile-nav"
        style={
          showMenu
            ? {
              backgroundColor: '#fff',
            }
            : {
              backgroundColor: `rgba(255, 255, 255, ${(400 - topDistance) / 40
                })`,
            }
        }
      >
        <div className="title">
          <img src={require('../../assets/logo_black@2x.png')} alt="" />
        </div>
        <div className="menu" onClick={handleClick}>
          {showMenu ? (
            <img src={require('../../assets/m_clear@2x.png')} alt="" />
          ) : (
              <img src={require('../../assets/m_menu@2x.png')} alt="" />
            )}
        </div>
        {!transparentBackground || showMenu ? (
          <button className="mob-download">
            {/* <NavLink to="/download" className="link">
              立即下载
            </NavLink> */}
            <span className="link" onClick={() => eventBus.emit("showOfflinePage")}>
              立即下载
            </span>
          </button>
        ) : null}
        {showMenu ? (
          <div className="down-menu">
            <div className="link">
              <NavLink
                exact={true}
                to="/"
                className="to"
                activeClassName="active"
              >
                首页
              </NavLink>
            </div>
            <div className="link">
              <NavLink
                exact={true}
                to="/features"
                className="to"
                activeClassName="active"
              >
                功能介绍
              </NavLink>
            </div>
            <div className="link">
              <NavLink
                exact={true}
                to="/about"
                className="to"
                activeClassName="active"
              >
                关于我们
              </NavLink>
            </div>
            <div className="link">
              {/* <NavLink
                exact={true}
                to="/download"
                className="to"
                activeClassName="active"
              >
                下载
              </NavLink> */}
              <span className="link" onClick={() => eventBus.emit("showOfflinePage")}>
              下载
              </span>
            </div>
            <div className="phone">客服：400-021-3668</div>
          </div>
        ) : null}
      </div>
      <div
        className="nav"
        style={{
          backgroundColor: `rgba(255, 255, 255, ${(632 - topDistance) / (632 - 513)})`,
        }}
      >
        <div className="navbar">
          <div className="navbar-left">
            <div className="logo-wrap">
              <img src={require('../../assets/logo_black@2x.png')} alt="" />
            </div>
            <ul className="tab">
              <li>
                <NavLink
                  exact={true}
                  to="/"
                  className="link"
                  activeClassName={
                    transparentBackground ? 'selected' : 'normal'
                  }
                >
                  首页
                </NavLink>
                <div
                  className="linkline"
                  style={
                    index === 0 ? { display: 'block' } : { display: 'none' }
                  }
                ></div>
              </li>
              <li>
                <NavLink
                  exact={true}
                  to="/features"
                  className="link"
                  activeClassName={
                    transparentBackground ? 'selected' : 'normal'
                  }
                >
                  功能介绍
                </NavLink>
                <div
                  className="linkline"
                  style={
                    index === 1 ? { display: 'block' } : { display: 'none' }
                  }
                ></div>
              </li>
              <li>
                <NavLink
                  exact={true}
                  to="/about"
                  className="link"
                  activeClassName={
                    transparentBackground ? 'selected' : 'normal'
                  }
                >
                  关于我们
                </NavLink>
                <div
                  className="linkline"
                  style={
                    index === 2 ? { display: 'block' } : { display: 'none' }
                  }
                ></div>
              </li>
            </ul>
          </div>
          <div className="navbar-right">
            <span className="tel">客服：400-021-3668</span>
            {/* <a
              href="https://pc.xiaoheiban.cn/login/"
              rel="noopener noreferrer"
              target="_blank"
              className="login"
            >
              网页版登录
            </a> */}
            <div className="client-downloads">
              {/* <NavLink to="/download" className="link">
                客户端下载
              </NavLink> */}
              <span className="link" onClick={() => eventBus.emit("showOfflinePage")}>
                客户端下载
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
