import React from "react";
import { NavLink } from "react-router-dom";

import "./index.scss";
import "./index-media.scss";

export default () => {
  return (
    <div className="footer">
      <div className="statement">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="to" onClick={() => {
          window.open(
            '//cs.xiaoheiban.cn/h5/#/privacy-policy/2?type=2',
            '_blank',
            'width=480,height=600'
          );
        }}>
          隐私保护
        </a>{" "}
        ｜{" "}
        <NavLink className="to" exact={true} to="feedback">
          反馈意见
        </NavLink>{" "}
      </div>
      <div className="address">
        上海晓信信息科技有限公司版权所有{" "}
        <a
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="noopener noreferrer"
          target="_blank"
        >
          沪ICP备15021070号-1
        </a>
      </div>
      <div className="records">
        <div className="beian-wrapper">
          <img src={require("../../assets/beian.png")} alt="" />
        </div>
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002003848 "
          rel="noopener noreferrer"
          target="_blank"
        >
          沪公网安备 31011002003848号
        </a>{" "}
        <a
          href="https://app.eduyun.cn/mapp/mappAppDetail?id=3411014a35e111ea9725fa163e4fea36"
          rel="noopener noreferrer"
          target="_blank"
        >
          教APP备3100001号
        </a>
      </div>
      {/* <div className="serve-img">
        <a
          href="https://ss.knet.cn/verifyseal.dll?sn=e190319310107772719kv2000000&userid=8IeOInNbj43dec5E&time=1607586230649&stoken=325cfd04485b62c81e64b08ee28d1b6e&pa=23064"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={require("../../assets/web_flag.gif")} alt="" />
        </a>
      </div> */}
    </div>
  );
};
