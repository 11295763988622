import React from 'react'
import Title from '../../../components/Title'
import './cooperation.scss'
import './cooperation-media.scss'

const lists = [
  {
    id: 1,
    src: require('../../../assets/paper0.jpg'),
    url:
      'http://zqb.cyol.com/html/2017-08/15/nw.D110000zgqnb_20170815_2-12.htm',
  },
  {
    id: 2,
    src: require('../../../assets/paper1.jpg'),
    url: 'http://www.shobserver.com/news/detail?id=61794',
  },
  {
    id: 3,
    src: require('../../../assets/paper2.jpg'),
    url: 'http://gd.people.com.cn/GB/n2/2017/0815/c123932-30611849.html',
  },
  {
    id: 4,
    src: require('../../../assets/paper3.jpg'),
    url: 'http://www.zj.chinanews.com.cn/news/2017/0815/16436.html',
  },
  {
    id: 5,
    src: require('../../../assets/paper4.jpg'),
    url: 'http://edu.cnr.cn/list/20170815/t20170815_523902167.shtml',
  },
  {
    id: 6,
    src: require('../../../assets/paper5.jpg'),
    url: 'http://www.yangtse.com/m/news/education/2017-08-15/450577.html',
  },
  {
    id: 7,
    src: require('../../../assets/paper6.jpg'),
    url: 'http://news.21cn.com/caiji/roll1/a/2017/0815/13/32618032.shtml',
  },
  {
    id: 8,
    src: require('../../../assets/paper7.jpg'),
    url: 'http://www.njdaily.cn/2017/0815/1643061.shtml',
  },
  {
    id: 9,
    src: require('../../../assets/paper8.jpg'),
    url: 'https://www.toutiao.com/a6452939288983552270/',
  },
  {
    id: 10,
    src: require('../../../assets/paper9.jpg'),
    url: 'http://news.163.com/17/0812/14/CRL5CR7H000187VE.html',
  },
  {
    id: 11,
    src: require('../../../assets/paper10.jpg'),
    url: 'http://society.huanqiu.com/shrd/2017-08/11128155.html',
  },
  {
    id: 12,
    src: require('../../../assets/paper11.jpg'),
    url: 'http://hn.ifeng.com/a/20170816/5917012_0.shtml',
  },
  {
    id: 13,
    src: require('../../../assets/paper12.jpg'),
    url: 'http://www.sh.xinhuanet.com/2017-08/11/c_136518777.htm',
  },
  {
    id: 14,
    src: require('../../../assets/paper13.jpg'),
    url: 'http://news.china.com/news100/11038989/20170815/31100759.html',
  },
  {
    id: 15,
    src: require('../../../assets/paper14.jpg'),
    url: 'http://henan.china.com.cn/edu/2017/0816/5482713.shtml',
  },
  {
    id: 16,
    src: require('../../../assets/paper15.jpg'),
    url: 'http://news.sznews.com/content/2017-08/15/content_17016459.htm',
  },
  {
    id: 17,
    src: require('../../../assets/paper16.jpg'),
    url: 'http://tech.sina.com.cn/roll/2017-08-15/doc-ifyixcaw4918013.shtml',
  },
  {
    id: 18,
    src: require('../../../assets/paper17.jpg'),
    url:
      'http://toutiao.chinaso.com/sh/detail/20170815/1000200032975781502757356223912080_1.html',
  },
  {
    id: 19,
    src: require('../../../assets/paper18.jpg'),
    url: 'http://news.gxtv.cn/201708/news_1916660081.html',
  },
  {
    id: 20,
    src: require('../../../assets/paper19.jpg'),
    url: 'http://heze.dzwww.com/shxw/201708/t20170815_16120230.htm',
  },
  {
    id: 21,
    src: require('../../../assets/paper20.jpg'),
    url: 'http://society.dbw.cn/system/2017/08/15/057746538_02.shtml',
  },
  {
    id: 22,
    src: require('../../../assets/paper21.jpg'),
    url: 'http://news.ishaanxi.com/2017/0815/701776.shtml',
  },
  {
    id: 23,
    src: require('../../../assets/paper22.jpg'),
    url:
      'http://www.chinahightech.com/html/chuangye/cyfh/2017/0815/425003.html',
  },
  {
    id: 24,
    src: require('../../../assets/paper23.jpg'),
    url: 'http://news.nen.com.cn/system/2017/08/15/020024645.shtml',
  },
  {
    id: 25,
    src: require('../../../assets/paper24.jpg'),
    url: 'http://news.changsha.cn/html/538/20170816/2250064.html',
  },
  {
    id: 26,
    src: require('../../../assets/paper25.jpg'),
    url: 'http://www.qzcns.com/qznews/2017/0815/493245.html',
  },
  {
    id: 27,
    src: require('../../../assets/paper26.jpg'),
    url: 'http://news.gog.cn/system/2017/08/15/015996574.shtml',
  },
  {
    id: 28,
    src: require('../../../assets/paper27.jpg'),
    url: 'http://news.sun0769.com/society/head/201708/t20170815_7515317.shtml',
  },
  {
    id: 29,
    src: require('../../../assets/paper28.jpg'),
    url: 'http://china.newssc.org/system/20170815/000808542.html',
  },
  {
    id: 30,
    src: require('../../../assets/paper29.jpg'),
    url:
      'http://news.qtv.com.cn/system/2017/08/15/014498351.shtml?vojijc=dew3u3',
  },
  {
    id: 31,
    src: require('../../../assets/paper30.jpg'),
    url: 'http://news.cnwest.com/content/2017-08/16/content_15282887.htm',
  },
  {
    id: 32,
    src: require('../../../assets/paper31.jpg'),
    url: 'http://news.chengdu.cn/2017/0815/1903012.shtml?phone=yes',
  },
  {
    id: 33,
    src: require('../../../assets/paper32.jpg'),
    url: 'http://sh.eastday.com/m/20170815/u1ai10786646.html',
  },
  {
    id: 34,
    src: require('../../../assets/paper33.jpg'),
    url: 'http://news.haiwainet.cn/n/2017/0815/c3541089-31069535.html',
  },
]

export default () => {
  const title = '新闻媒体争相报道晓黑板'
  return (
    <div className="cooperation">
      <Title title={title} />
      <div className="coo-content">
        {lists.map((item) => (
          <div key={item.id} className="content">
            <a
              href={item.url}
              className="link-media"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={item.src} alt="" />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
