import React, { useRef, useState, useEffect } from 'react'

import './index.scss'
import './index.media.scss'
import Line from '../../components/Line'
import animate from '@minax/animate-scroll'
import { OssUpload } from '../../utils/oss'
import { feedbackAdd } from '../../api/request'
import Footer from '../Footer'

export default () => {
  const inputEL = useRef(null)
  const inputEL1 = useRef(null)
  const inputEL2 = useRef(null)
  const inputEL3 = useRef(null)
  const [imgLists, setImgLists] = useState([])

  const [formData, setFormData] = useState({
    content: '',
    images: [],
    questionType: '',
    mobile: '',
  })

  useEffect(() => {
    const scrollY = window.scrollY
    setTimeout(() => {
      animate(
        scrollY,
        0,
        (px) => {
          window.scrollTo(0, px)
        },
        { spendTime: 600, animationFunc: 'easeInOutExpo' }
      )
    }, 0)
  }, [])

  function handleClick() {
    inputEL.current.value = null
    inputEL.current.click()
  }

  async function handleChange(e) {
    const { code, url } = await OssUpload.upload({
      fileData: e.target.files,
    })

    if (code !== 1) {
      alert('上传失败')
      return
    }

    setImgLists([...imgLists, { original: url }])
  }

  function onContentInput(e) {
    setFormData({ ...formData, content: e.target.value })
  }

  function onQuestionTypeChange(e) {
    setFormData({ ...formData, questionType: e.target.value })
  }

  function onMobileInput(e) {
    setFormData({ ...formData, mobile: e.target.value })
  }

  function handleDeleteImg(index) {
    const cpImgLists = [...imgLists]
    cpImgLists.splice(index, 1)
    setImgLists(cpImgLists)
  }

  async function handleSubmit() {
    if (formData.content === '') {
      inputEL1.current.style.display = 'inline-block'
      return
    }
    if (formData.questionType === '') {
      inputEL2.current.style.display = 'inline-block'
      return
    }
    if (
      formData.mobile === '' ||
      !/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/.test(
        formData.mobile
      )
    ) {
      inputEL3.current.style.display = 'inline-block'
      return
    }

    const { code } = await feedbackAdd({ ...formData, images: imgLists })
    if (code === 0) {
      setFormData({ content: '', images: [], questionType: '', mobile: '' })
      setImgLists([])
      inputEL1.current.style.display = 'none'
      inputEL2.current.style.display = 'none'
      inputEL3.current.style.display = 'none'
      alert('反馈成功')
    }
  }
  return (
    <div className="feedback-container">
      <Line />
      <div className="wrapper">
        <div className="feedback-title">意见反馈</div>
        <div className="option-title">
          <span className="required-flag">*</span> 问题描述
          <span ref={inputEL1} className="error-tip none">
            请输入问题描述
          </span>
        </div>
        <textarea
          onChange={onContentInput}
          value={formData.content}
          placeholder="点击输入您对晓黑板的建议和意见，如有任何问题欢迎拨打客服电话：400-021-3668"
        ></textarea>
        <div className="option-title">
          <span className="required-flag">*</span> 问题类型
          <span ref={inputEL2} className="error-tip none">
            请选择问题类型
          </span>
        </div>
        <select
          placeholder="请选择问题类型"
          value={formData.questionType}
          onChange={onQuestionTypeChange}
        >
          <option value="">请选择问题类型</option>
          <option value="欺诈行为">欺诈行为</option>
          <option value="政治敏感">政治敏感</option>
          <option value="色情低俗">色情低俗</option>
          <option value="诱导行为">诱导行为</option>
          <option value="违法犯罪">违法犯罪</option>
          <option value="不实信息">不实信息</option>
          <option value="其他">其他</option>
        </select>
        <div className="option-title">
          <span className="required-flag">*</span> 联系方式
          <span ref={inputEL3} className="error-tip none">
            请输入正确的手机号码
          </span>
        </div>
        <input
          type="number"
          placeholder="请输入您的联系方式电话"
          value={formData.mobile}
          onChange={onMobileInput}
        />
        <div className="option-title">添加截图</div>
        <div className="upload-container">
          {imgLists.map((item, index) => {
            return (
              <div className="uploaded-show" key={index}>
                <img src={item.original} alt="" />
                <div
                  className="delete-container"
                  onClick={() => handleDeleteImg(index)}
                >
                  <img
                    src={require('../../assets/icon_delete@2x.png')}
                    alt=""
                  />
                </div>
              </div>
            )
          })}
          {imgLists.length < 9 && (
            <div className="upload-button" onClick={handleClick}>
              <input
                className="upload-input"
                ref={inputEL}
                type="file"
                onChange={handleChange}
                accept="image/*"
              />
              <img src={require('../../assets/upload@2x.png')} alt="" />
            </div>
          )}
        </div>
        <div className="submit-button" onClick={handleSubmit}>
          提交
        </div>
      </div>
      <div className="placeholder-bootom"></div>
      <Footer />
    </div>
  )
}
