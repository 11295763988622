import React, { useState } from 'react'
import './tips.scss'

export default () => {
  const [clearTip, setClearTip] = useState(false)
  return (
    <div className={clearTip ? 'clear-tip' : 'tips'}>
      <img
        src={require('../../../assets/net_security.png')}
        alt=""
        width="225"
        height="auto"
      />
      <div className="clear" onClick={() => setClearTip(true)}>
        <img
          src={require('../../../assets/net_security_close.png')}
          width="20"
          height="20"
          alt=""
        />
      </div>
    </div>
  )
}
