import { httpSign } from '@heibanfe/encrypt'

const toString = Object.prototype.toString

function isDate(val) {
    return toString.call(val) === '[object Date]'
}

function isPlainObject(val) {
    return toString.call(val) === '[object Object]'
}

function encode(val) {
    return encodeURIComponent(val)
        .replace(/%40/g, '@')
        .replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',')
        .replace(/%20/g, '+') // 空格变+号
        .replace(/%5B/gi, '[')
        .replace(/%5D/gi, ']')
}

function buildURL(url, params) {
    if (!params) {
        return url
    }
    const parts = []

    Object.keys(params).forEach(key => {
        const val = params[key]
        if (val === null || typeof val === 'undefined') {
            return
        }
        let values = []
        if (Array.isArray(val)) {
            values = val
            key += '[]'
        } else {
            values = [val]
        }
        values.forEach(val => {
            if (isDate(val)) {
                val = val.toISOString()
            } else if (isPlainObject(val)) {
                val = JSON.stringify(val)
            }
            parts.push(`${encode(key)}=${encode(val)}`)
        })
    })

    let serializedParams = parts.join('&')
    const sign = buildSignObj(url, serializedParams || '')
    const markIndex = url.indexOf('#')
    if (markIndex !== -1) {
        url = url.slice(0, markIndex)
    }
    url += url.indexOf('?') === -1 ? '?' : ''
    url += `sign=${JSON.stringify(sign)}`
    if (serializedParams) {
        url += (url.indexOf('?') === -1 ? '?' : '&') + serializedParams
    }
    // console.log('url', url)
    return url
}

function buildSignObj(url, serializedParams) {
    // console.log('url', url, 'serializedParams', serializedParams)
    const sign = httpSign(url, serializedParams, 'GET', '')
    // console.log('sign', sign)
    const signArray = sign.split(';')
    const signObj = {}
    signArray.forEach(s => {
        // eslint-disable-next-line no-useless-escape
        const array = s.split(/\=(.+)/)
        signObj[encode(array[0])] = `${encode(array[1])}`
    })
    return signObj
}
let appEnv = ''
let userId = ''

const postData = async (url, params, env) => {
    const biUrl = buildURL(url, params)
    let image = new Image(0, 0)
    let finalUrl = ''

    if (appEnv === '') {
        userId = params.userId
        appEnv = env
    }
    const DOMAINS = {
        DEBUG: 'https://test-trace.xiaoheiban.cn',
        DEVELOPMENT: 'https://test-trace.xiaoheiban.cn',
        STAGING: 'https://pre-trace.xiaoheiban.cn',
        PRODUCTION: 'https://trace.xiaoheiban.cn'
    }
    finalUrl = DOMAINS[appEnv.toUpperCase()] + biUrl

    image.src = finalUrl
    console.log('发送埋点请求了', image.src)
    console.log('发送埋点请求了2', biUrl)
    console.log('发送埋点请求了3', process.env)
}

/**
 * 数据统计查询quality-course
 * 查询权限找 @wangchao或者数据组的人开通
 * https://dashboard.xiaoheiban.cn/d/iD6OrP8Wz/1-yong-hu-shi-jian-cha-xun?orgId=2
 *
 * @param {Object} data 传递的数据
 * @param {Number} data.pageId  埋点类型
 * @param {String} data.desc  说明 web_XXX
 * @param {String} data.path 路径
 * @param {String?} data.typeId 具体埋点数据 类型id
 * @param {String?} data.userId 用户id
 * @param {Number?} data.createTime 创建时间
 * @param {Object?} data.params 额外参数对象
 * */
export default async (params, env) => {
    // TODO 根据实际情况获取 userId
    params.userId = params.userId || ''
    params.createTime = new Date().getTime()
    // console.log('trace', params)

    postData('/trace/web-insert', params, env)
}
