import React from 'react'
import Title from '../../../components/Title'
import './communicate.scss'
import './communicate-media.scss'
import { useRef, useEffect } from 'react'

const lists = [
  {
    id: 1,
    icon: require('../../../assets/pc_message@2x.png'),
    topName: `信息刷屏，重要`,
    bottomName: '信息无法确定家长收到',
  },
  {
    id: 2,
    icon: require('../../../assets/pc_group@2x.png'),
    topName: `群聊沟通稍有`,
    bottomName: `不慎容易引起小摩擦`,
  },
  {
    id: 3,
    icon: require('../../../assets/icon_previcy@2x.png'),
    topName: '老师和家长的',
    bottomName: '个人隐私难以保护',
  },
  {
    id: 4,
    icon: require('../../../assets/icon_data@2x.png'),
    topName: '微信群里',
    bottomName: '资料仅能保存3天',
  },
]

export default ({ onNavbarBackgroundChange }) => {
  const title = '家校沟通，完美取代微信群'
  const elRef = useRef(null)

  useEffect(() => {
    const action = () => {
      const el = elRef.current
      if (el) {
        const { top } = el.getBoundingClientRect()
        onNavbarBackgroundChange(top > 0, top)
      }
    }
    action()
    window.addEventListener('scroll', action)
    return () => {
      window.removeEventListener('scroll', action)
    }
  }, [onNavbarBackgroundChange])

  return (
    <div className="communicate" ref={elRef}>
      <Title title={title} />
      <div className="com-content">
        {lists.map((item) => (
          <div className="content" key={item.id}>
            <div className="icon">
              <img src={item.icon} alt="" />
            </div>
            <p className="name">
              <span>{item.topName}</span>
              <span>{item.bottomName}</span>
            </p>
          </div>
        ))}
      </div>
      <div className="xiaoheiban">
        <div className="content">
          <span className="title">晓黑板，扫除以上痛点</span>
          <img
            className="src"
            src={require('../../../assets/icon_success.png')}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}
