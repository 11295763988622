import React from 'react'
import Title from '../../../components/Title'
import './homework.scss'
import './homework-media.scss'

const lists = [
  {
    id: 1,
    icon: require('../../../assets/icon_voice@2x.png'),
    name: '背诵可以语音上传',
  },
  {
    id: 2,
    icon: require('../../../assets/icon_picture@2x.png'),
    name: '绘画写作等可以图片上传',
  },
  {
    id: 3,
    icon: require('../../../assets/pc_video@2x.png'),
    name: '体育锻炼可以视频上传',
  },
]

export default () => {
  const title = '多媒体形式完成作业'
  return (
    <div className="homework">
      <Title title={title} />
      <div className="home-content">
        {lists.map((item) => (
          <div className="content" key={item.id}>
            <div>
              <img src={item.icon} alt="" />
            </div>
            <p className="name">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
