import React from "react";
import "./banner.scss";
import "./banner-media.scss";
import eventBus from "../../../utils/events";

export default () => {
  return (
    <div className="banner">
      <div className="i-banner">
        <img src={require("../../../assets/banner_img@2x.png")} alt="" />
      </div>
      <div className="mark">
        <div className="mark-container">
          <div className="top">
            <p>让学校教育更高效</p>
            <p>让家庭教育更科学</p>
          </div>
          <div className="mob-bottom">
            <div className="btn">
              {/* <NavLink to="/download" className="link">
                立即下载
              </NavLink> */}
              <span className="link" onClick={() => eventBus.emit("showOfflinePage")}>
                立即下载
              </span>
            </div>
          </div>
          {/* <div className="bottom">
            <div className="dask">
              <NavLink to="/download" className="link">
                桌面端下载
              </NavLink>
            </div>
            <div className="e-wrap">
              <div className="phone">手机端下载</div>
              <div className="e-code">
                <img src={require("../../../assets/xhb-qr-code.png")} alt="" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
