import React, { forwardRef } from 'react'
import './index.scss'
import './index-media.scss'

export default forwardRef(({ style, infoData }, ref) => {
  return (
    <div className="info" style={style} ref={ref}>
      <div className="personal">
        <div className="avatar">
          <img src={infoData.avatar} alt="" />
        </div>
        <div>
          <div className="introduction">
            <div className="name">{infoData.name}</div>
            <div className="stage">{infoData.stage}</div>
          </div>
          <div className="address">{infoData.address}</div>
        </div>
      </div>
      <div className="app-info">
        <div className="time">
          <div className="app-info-title">加入时间</div>
          <div className="app-info-content">{infoData.joinTime}</div>
        </div>
        {renderAppInfo('加入学校', infoData.schoolNumber)}
        {renderAppInfo('创建班级', infoData.classNumber)}
        {renderAppInfo('加入班级', infoData.joinClassNumber)}
        {renderAppInfo('成员数', infoData.memberNumber)}
        {renderAppInfo('发送消息', infoData.messageNumber)}
      </div>
      <div className="desc">{infoData.desc}</div>
    </div>
  )
})

function renderAppInfo(title, number) {
  return (
    number > 0 && (
      <div className="class">
        <div className="app-info-title">{title}</div>
        <div className="app-info-content">{number}</div>
      </div>
    )
  )
}
