import React from 'react'
import Banner from './banner/banner'
import Communicate from './communicate/communicate'
import Notice from './notice/notice'
import Homework from './homework/homework'
import Worktime from './worktime/worktime'
import Record from './record/record'
import Future from './future/future'
import Tips from './tips/tips'
import Cooperation from './cooperation/cooperation'
import Footer from '../Footer'
import workImg from '../../assets/stay@2x.png'
import telImg from '../../assets/ask@2x.png'
import mobTime from '../../assets/mob_time@2x.png'
import mobEducation from '../../assets/mob_education@2x.png'

export default ({ onNavbarBackgroundChange }) => {
  return (
    <>
      <Banner />
      <Communicate onNavbarBackgroundChange={onNavbarBackgroundChange} />
      <Notice />
      <Homework />
      <Worktime
        title="老师可以设置工作时间"
        src={workImg}
        mobSrc={mobTime}
        width={659}
      />
      <Record />
      <Worktime
        title="引导家校同育 完成【综合素质】"
        src={telImg}
        mobSrc={mobEducation}
        width={659}
      />
      <Future />
      <Cooperation />
      <Footer />
      <Tips />
    </>
  )
}
