import React from 'react'
import Line from '../../components/Line'
import Slider from '../../components/Slider'
import Title from '../../components/Title'
import Footer from '../Footer'

import './index.scss'
import './index.media.scss'

const textPoints = [
  '晓黑板目前包括“发通知”、“开讨论”、“做调查”、“发视频”、“晓成长”等功能。',
  '老师下载晓黑板APP，可以立刻创建自己的班级；',
  '“发通知”功能中，老师可以向家长发送即时消息，系统会自动反馈每位家长是否收到信息；',
  '讨论和调查功能，让老师以更恰当的方式，及时掌握每位家长的想法；',
  '“晓成长”，帮助老师和家长随时记录和分享孩子的精彩瞬间；',
  '晓黑板所有信息支持图文、语音、视频、文字、表情等形式，晓黑板采用星状联络结构，规避了微信群和QQ群等传统社交群的网状杂乱结构，有效避免了家校沟通中不必要的摩擦。',
  '由晓黑板专业团队创作的《晓周刊》每周三出品，是您身边的亲子教育专家，提供家庭教育、家校沟通、升学择校的最专业资讯',
]

const reportData = [
  {
    title: '上海空中课堂平台“晓黑板”: 机遇与挑战',
    img: '',
    video:
      'https://test-oss.xiaoheiban.cn/202010/4f17654f-f9ba-4449-abd1-eff56432ebfc.mp4',
    desc:
      '上海电视台和东方电视台，分别以《晓黑板：直面技术挑战连轴转 在线教育市场觅良机》、《上海空中课堂平台“晓黑板”：机遇与挑战并存》为题，对晓黑板进行了长达3分钟的新闻报道。',
  },
  {
    title: '《促进在线新经济发展行动方案》',
    img: require('../../assets/report_img1@2x.png'),
    video: '',
    desc:
      '4月13日，上海市《促进在线新经济发展行动方案》正式发布。《光明日报》头版头条、《工人日报》经济版头条等报导疫情期间上海催生了一批“新经济”，大致分为3大类。 一类是“无中生有”，第二类是“有中启转”，其中特别提到疫情期间，上海的老师和学生通过“晓黑板”直播课堂进行直播教学和学习互动。第三类是“转中做大”。 上海市经济信息化委总工程师刘平透露，这3类12个领域代表未来发展方向。',
  },
  {
    title: '“上下楼”唇齿相依，产业链通了！',
    img: require('../../assets/report_img3@2x.png'),
    video: '',
    desc:
      '上海市委机关报《解放日报》头版头条：报导晓黑板由家校沟通平台迅速升级为空中课堂，并且带动产业链互通的故事。',
  },
  {
    title: '“我为武汉送祝福”书画征集活动',
    img: require('../../assets/report_img2@2x.png'),
    video: '',
    desc:
      '疫情刚开始，晓黑板就在平台内发起“我为武汉送祝福”的书画征集活动，有1500多位孩子参加。中国新闻社、新民晚报、青年报、上海广播电台、第一教育、广州日报等对此进行了专题报道。 ',
  },
]

export default () => {
  return (
    <div className="about">
      <div className="placeholder"></div>
      <Line />
      <div className="good-tools">
        <Title className="about-title" title={'晓黑板——好老师的好工具'} />

        <div className="tools-container">
          <p>
            晓黑板是基于青少年的成长规律、根据老师与家长的需求定制的一款专业、科学、现代化的家校沟通与家校管理工具。截止2017年7月，晓黑板在全国覆盖了1万5千所学校，其中包括上海的3000所学校。目前全国有10余万教师和近百万家庭使用晓黑板。
          </p>
          <div className="text-container">
            {textPoints.map((t, index) => (
              <div className="text-option-wrapper" key={index}>
                <div className="point"></div>
                <div className="content">{t}</div>
              </div>
            ))}
          </div>

          <p>
            无论老师还是家长，晓黑板希望与您一起，陪伴和见证每一个孩子的成长，让学校教育更高效，让家庭教育更科学！
          </p>
          <p>
            晓黑板由上海晓信信息科技有限公司出品，该公司成立于2015年，深耕中国K12基础教育领域的互联网应用和信息化服务。晓信科技创始团队拥有超过十年的教育及互联网行业积累，致力打造“平台应用+服务拓展+内容增值”的教育信息化生态圈。
          </p>
        </div>
      </div>

      <div className="news-report">
        <div className="report-container">
          <Title title={'新闻报道'} className="about-title" />
          <div className="report-slider">
            <Slider
              settings={{
                customPaging: (i) => <div />,
                nextArrow: <NextArrow customClassName="arrow next" />,
                prevArrow: <NextArrow customClassName="arrow prev" />,
              }}
              renderSlider={reportData.map((item, index) => (
                <div className="slider-container" key={index}>
                  <div className="slider-header">{item.title}</div>
                  <div className="report-content">
                    <div>
                      {item.img !== '' && <img src={item.img} alt=""></img>}
                      {item.video !== '' && (
                        <video
                          poster="https://pro-image.xiaoheiban.cn/vto/feedback_1603336684428.png"
                          controls
                          name="media"
                        >
                          <source src={item.video} type="video/mp4" />
                        </video>
                      )}
                    </div>
                  </div>
                  <div className="report-desc">{item.desc}</div>
                </div>
              ))}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const NextArrow = ({ className, style, onClick, customClassName }) => (
  <div
    onClick={onClick}
    style={{ width: '38px', height: '38px', ...style }}
    className={className + ' hiddenBefore'}
  >
    <div className={customClassName}></div>
  </div>
)
