import React, { useEffect } from "react";
import Line from "../../components/Line";
import { downloadOfUrl } from "../../utils/utils";
import eventBus from "../../utils/events";
import animate from "@minax/animate-scroll";
import Footer from "../Footer";
import "./index.scss";
import "./index.media.scss";

const getPcPlatform = () => {
  if (/Mac OS X/i.test(navigator.userAgent)) {
    return "晓黑板 macOS 客户端";
  } else {
    return "晓黑板 Windows 客户端";
  }
};

const getMobilePlatform = () => {
  if (/Android/.test(navigator.userAgent)) {
    return "下载晓黑板 安卓版";
  } else if (/iPhone/.test(navigator.userAgent)) {
    return "下载晓黑板 iOS 版";
  } else {
    return "下载晓黑板 iPadOS 版";
  }
};

const getMobileToApplication = () => {
  if (/Android/.test(navigator.userAgent)) {
    return "https://a.app.qq.com/o/simple.jsp?pkgname=org.xinkb.blackboard.android";
  } else {
    return "https://apps.apple.com/cn/app/%E6%99%93%E9%BB%91%E6%9D%BF-%E5%AE%B6%E6%A0%A1%E6%B2%9F%E9%80%9A%E7%A5%9E%E5%99%A8/id1003713373?l=en";
  }
};

const lists = [
  { id: 0, name: "Windows", src: require("../../assets/pc_win@2x.png") },
  { id: 1, name: "macOS", src: require("../../assets/pc_mac@2x.png") },
  { id: 2, name: "iOS/iPadOS", src: require("../../assets/pc_iphone@2x.png") },
  { id: 3, name: "Android", src: require("../../assets/pc_android@2x.png") },
];

const mobileLists = [
  { id: 0, name: "Windows", src: require("../../assets/win_grey@2x.png") },
  { id: 1, name: "macOS", src: require("../../assets/mac_grey@2x.png") },
  {
    id: 2,
    name: "iOS/iPadOS",
    src: require("../../assets/iphone_grey@2x.png"),
  },
  { id: 3, name: "Android", src: require("../../assets/android_grey@2x.png") },
];

export default () => {
  useEffect(() => {
    const scrollY = window.scrollY;
    setTimeout(() => {
      animate(
        scrollY,
        0,
        (px) => {
          window.scrollTo(0, px);
        },
        { spendTime: 600, animationFunc: "easeInOutExpo" }
      );
    }, 0);
  }, []);

  const lastVersionMac = "7.12.83";
  const lastVersionWin = "7.12.83";

  const handleClick = () => {

    const macUrl = `https://pc-download.xiaoheiban.cn/prod/%E6%99%93%E9%BB%91%E6%9D%BF-${lastVersionMac}-latest.dmg`;

    const winUrl = `https://pc-download.xiaoheiban.cn/prod/%E6%99%93%E9%BB%91%E6%9D%BF-${lastVersionWin}-latest.exe`;

    if (/Mac OS X/i.test(navigator.userAgent)) {
      const filename = "晓黑板 macOS 客户端.dmg";
      downloadOfUrl(macUrl, filename);
    } else {
      const filename = "晓黑板 windows 客户端.exe";
      downloadOfUrl(winUrl, filename);
    }
  };

  const downloadClient = (id) => {

    const macUrl = `https://pc-download.xiaoheiban.cn/prod/%E6%99%93%E9%BB%91%E6%9D%BF-${lastVersionMac}-latest.dmg`;

    const winUrl = `https://pc-download.xiaoheiban.cn/prod/%E6%99%93%E9%BB%91%E6%9D%BF-${lastVersionWin}-latest.exe`;

    if (id === 0) {
      const filename = "晓黑板 windows 客户端.exe";
      downloadOfUrl(winUrl, filename);
    } else {
      const filename = "晓黑板 macOS 客户端.dmg";
      downloadOfUrl(macUrl, filename);
    }
  };

  const coverDownload = (id) => {
    if ([0, 1].includes(id)) {
      return (
        <div className="wrap-cover">
          <div
            className="cover"
            onClick={() => {
              downloadClient(id);
            }}
          >
            <img
              className="download-logo"
              src={require("../../assets/pc_download@2x.png")}
              alt=""
            />
            <span className="name">立即下载</span>
            <h6 className="version">
              {id === 1 ? "晓黑板macOS版" : "晓黑板Windows版"}
            </h6>
          </div>
        </div>
      );
    }
    if ([2, 3].includes(id)) {
      return (
        <div className="wrap-cover">
          <div className="cover">
            <img
              className="code"
              src={
                "https://pro-image.xiaoheiban.cn/202012/9341d057-bd8b-4e02-b6ca-1e60ad89ddc3.png"
              }
              alt=""
            />
            <span className="share">扫码下载</span>
            <h6 className="version">
              {id === 3 ? "晓黑板Android版" : "晓黑板iOS/iPadOS版"}
            </h6>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="media-download">
        <div className="overflow-y">
          <div className="pad-line"></div>
          <div className="media-top">
            <div className="media-logo">
              <img src={require("../../assets/img_logo1024@2x.png")} alt="" />
            </div>
            <div className="media-btn" onClick={() => eventBus.emit("showOfflinePage")}>
              <a
                // href={getMobileToApplication()}
                rel="noopener noreferrer"
                target="_blank"
                className="app-download"
              >
                {getMobilePlatform()}
              </a>
            </div>
          </div>
          <div className="media-bottom">
            <div className="media-support">
              <span className="title">同时支持多端版本</span>
              <div className="content-wrap">
                {mobileLists.map((item) => (
                  <div className="content" key={item.id}>
                    <img src={item.src} alt="" />
                    <span className="device">{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <div className="left-circle"></div>
        <div className="right-circle"></div>
      </div>
      <div className="download">
        <div className="placeholder"></div>
        <Line />
        <div className="container">
          <div className="top-wrap"></div>
          <div className="bottom-wrap"></div>
          <div className="suspension">
            <div className="down-title">
              <div className="app-logo">
                <img src={require("../../assets/img_logo1024@2x.png")} alt="" />
              </div>
              <p className="name">{getPcPlatform()}</p>
              <p className="des">同时支持 iOS、iPadOS、Android、Windows 版本</p>
              <div className="btn-down" onClick={handleClick}>
                立即下载
              </div>
            </div>
            <div className="down-figure">
              <img src={require("../../assets/img_mockup@2x.png")} alt="" />
            </div>
            <div className="down-type">
              {lists.map((item) => (
                <div key={item.id} className="content">
                  <img src={item.src} alt="" />
                  <span className="name">{item.name}</span>
                  {coverDownload(item.id)}
                </div>
              ))}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
