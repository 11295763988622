import ossClient from '@heibanfe/oss'

/**
 * oss 上传
 */
export class OssUpload {
  constructor(options, config) {
    const urlPath = '/internal/sign/alicloud/assumerole'
    const urlQuery = 'expired=3600'

    options.fileData = [...options.fileData]
    options.fileSize = 300 // 文件上传大小限制

    this.options = options
    this.config = {
      bucketName: `${process.env.REACT_APP_BUCKET_NAME}`,
      bucketRegion: 'oss-cn-hangzhou',
      customizeDomain: `${process.env.REACT_APP_CUSTOMIZE_DOMAIN_NAME}`,
      stsUrl: `${process.env.REACT_APP_OSS_API}${urlPath}?${urlQuery}`,
      stsHeaders: {
        'app-version': '100',
        platform: 'web',
      },
      ...config,
    }
  }

  /**
   * 上传
   * @param options - 上传参数配置
   * @param config - oss 参数配置，可选
   */
  static async upload(options, config) {
    const oss = new OssUpload(options, config)

    const client = await ossClient.initOSS(oss.options, oss.config)
    return client.start()
  }
}
