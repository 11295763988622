import React from 'react'
import './index.scss'

const Title = ({ title, className, lineName }) => {
  return (
    <div className={`warp-title`}>
      <p className={`title ${className}`}>{title}</p>
      <span className={`line ${lineName}`}></span>
    </div>
  )
}

export default Title
