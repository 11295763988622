/*
 * @Descripttion: 
 * @Author: olei<wangzheng_jzb@100tal.com>
 * @Date: 2022-04-06 22:32:13
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-04-07 10:00:47
 */
import React, { useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import withOfflineTip from './offline'

import Home from './pages/Home'
import Features from './pages/Features'
import About from './pages/About'
import Navbar from './pages/Navbar'
import NoMatch from './pages/404'
import Feedback from './pages/Feedback'
import Download from './pages/Download'
import Provacy from './pages/Protect'
import Share from './pages/Share'
import App from './pages/App'
import { Suspense } from 'react'
import './style/style.scss'
import JumpDingtalk from './pages/JumpDingtalk'

const UserAgreement = React.lazy(() => import('./pages/UserAgreement/index'))
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy/index'))
const TransmissionSdk = React.lazy(() => import('./pages/TransmissionSdk/index'))
const AppPermissions = React.lazy(() => import('./pages/AppPermissions/index'))
const PrivacyPolicySummary = React.lazy(() => import('./pages/PrivacyPolicySummary/index'))
const PersonInfoList = React.lazy(() => import('./pages/PersonInfoList/index'))


const withNavbar = (Cpt) => () => {
  const [isTransparent, setIsTransparent] = useState(false)
  const [scrollTops, setScrollTops] = useState(0)
  return (
    <>
      <Navbar transparentBackground={isTransparent} topDistance={scrollTops} />
      <Cpt
        onNavbarBackgroundChange={(v, s) => {
          setIsTransparent(v)
          setScrollTops(s)
        }}
      />
    </>
  )
}

export default () => {
  return (
    <Suspense fallback={null}>
      <HashRouter>
        <Switch>
          <Route exact path="/" component={withOfflineTip(withNavbar(Home))} />
          <Route exact path="/features" component={withOfflineTip(withNavbar(Features))} />
          <Route exact path="/features/:type" component={withOfflineTip(withNavbar(Features))} />
          <Route exact path="/about" component={withOfflineTip(withNavbar(About))} />
          <Route exact path="/feedback" component={withOfflineTip(withNavbar(Feedback))} />
          <Route exact path="/download" component={withOfflineTip(withNavbar(Home))} />
          <Route exact path="/provacy" component={withOfflineTip(withNavbar(Provacy))} />
          <Route exact path="/share" component={withOfflineTip(Share)} />
          <Route exact path="/app" component={withOfflineTip(App)} />
          <Route exact path='/user-agreement' component={withOfflineTip(UserAgreement)} />
          <Route exact path='/privacy-policy' component={withOfflineTip(PrivacyPolicy)} />
          <Route exact path='/privacy-policy/:version' component={withOfflineTip(PrivacyPolicy)} />
          <Route exact path='/privacy-policy-summary' component={withOfflineTip(PrivacyPolicySummary)} />
          <Route exact path='/transmission-sdk' component={withOfflineTip(TransmissionSdk)} />
          <Route exact path='/app-permissions' component={withOfflineTip(AppPermissions)} />
          <Route exact path='/person-info-list' component={withOfflineTip(PersonInfoList)} />
          <Route exact path='/jump-dingtalk' component={JumpDingtalk} />
          <Route exact path="*" component={withOfflineTip(NoMatch)} />
        </Switch>
      </HashRouter>
    </Suspense>
  )
}
