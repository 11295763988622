import React from 'react'
import Title from '../../../components/Title'
import './record.scss'
import './record-media.scss'

export default () => {
  const title = '记录孩子的点点滴滴'
  return (
    <div className="record">
      <Title title={title} />
      <p className="sub-title">
        所有孩子的记录晓黑板都会保存可以封装成册，老师和家长可以各种形式下载导出
      </p>
      <div className="i-record">
        <img src={require('../../../assets/record@2x.png')} alt="" />
      </div>
    </div>
  )
}
