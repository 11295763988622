import React, { useEffect } from 'react'
import Line from '../../components/Line'
import animate from '@minax/animate-scroll'
import Footer from '../Footer'
import './index.scss'
import './index.media.scss'

export default () => {
  useEffect(() => {
    const scrollY = window.scrollY
    setTimeout(() => {
      animate(
        scrollY,
        0,
        (px) => {
          window.scrollTo(0, px)
        },
        { spendTime: 600, animationFunc: 'easeInOutExpo' }
      )
    }, 0)
  }, [])
  return (
    <div className="protect">
      <div className="placeholder"></div>
      <Line />
      <div className="content-wrap">
        <h3 className="title">隐私权限</h3>
        <div className="mob-wrap">
          <h3 className="mob-title">隐私保护</h3>
          <div className="line"></div>
        </div>
        <div>
          <h5>法律声明及隐私权政策</h5>
          <p>
            上海晓信信息科技有限公司（下称“我们”或“晓黑板”）尊重并保护所有使用晓黑板用户的个人隐私权。为了给您提供更准确、更有个性化的服务，晓黑板会按照本隐私权政策的规定使用和披露您的个人信息。但晓黑板将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，晓黑板不会将这些信息对外披露或向第三方提供。晓黑板会不时更新本隐私权政策。我们希望通过本隐私政策向您清晰地介绍我们对您个人信息的处理方式，因此我们建议您完整地阅读本隐私政策，以帮助您了解维护自己隐私权的方式。
          </p>
          <h5>法律声明</h5>
          <p>
            访问、浏览或使用晓黑板，表明您已阅读、理解并同意接受以下条款的约束，并遵守所有适用的法律和法规。您一旦使用晓黑板，则须秉着诚信的原则遵守以下条款。
          </p>
          <h5>一般原则</h5>
          <p>
            以下规则适用于所有晓黑板用户或浏览者，晓黑板可能随时修改这些条款。您应经常访问本页面以了解当前的条款，因为这些条款与您密切相关。这些条款的某些条文也可能被晓黑板软件及平台中某些页面上或某些具体服务明确指定的法律通告或协议、条款所取代，您应该了解这些内容，一旦接受本条款，即意味着您已经同时详细阅读并接受了这些被引用或取代的条款。
          </p>
          <h5>权利说明</h5>
          <p>
            上海晓信信息科技有限公司及其关联公司对其发行的或与合作公司共同发行的包括但不限于晓黑板软件及相关产品或服务的全部内容，享有知识产权，受法律保护。如果相关内容未含权利声明，并不代表晓黑板对其不享有权利和不主张权利，您应根据法律、法规及诚信原则尊重权利人的合法权益并合法使用该内容。
            <br />
            未经上海晓信信息科技有限公司书面许可，任何单位及个人不得以任何方式或理由对上述软件、产品、服务、信息、文字的任何部分进行使用、复制、修改、抄录、传播或与其它产品捆绑使用、销售,或以超级链路连接或传送、存储于信息检索系统或者其他任何商业目的的使用，但对于非商业目的的、个人使用的下载或打印（未作修改，且须保留该内容中的版权说明或其他所有权的说明）除外。
            <br />
            晓黑板软件及平台中使用和显示的商标和标识（以下统称"商标"）是上海晓信信息科技有限公司及其关联公司注册和未注册的有关商标，受法律保护，但注明属于其他方拥有的商标、标志、商号除外。晓黑板软件中所载的任何内容，未经上海晓信信息科技有限公司书面许可，任何人不得以任何方式使用晓黑板名称及晓黑板软件的商标、标记。
          </p>
          <h5>用户信息</h5>
          <p>
            为晓黑板提供相应服务之必须，您以自愿填写的方式提供注册所需的身份信息、电话以及其他类似信息，则表示您已经了解并接受所提供信息的用途，同意晓黑板为实现该特定目的使用您的信息。除此信息之外，其他任何您发送或提供给晓黑板的材料、信息或文本(以下统称信息)均将被视为非保密和非专有的。晓黑板对这些信息不承担任何义务。同时如果您提交时没有特别声明的，可视为同意晓黑板及其授权人可以因商业或非商业的目的复制、透露、分发、合并和以其他方式利用这些信息和所有数据、图像、声音、文本及其他内容。您可阅读下面的晓黑板隐私政策以了解更加详细的内容。
          </p>
          <h5>责任限制声明</h5>
          <p>
            不论在何种情况下，晓黑板对由于信息网络设备维护、信息网络连接故障、智能终端、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、起义、骚乱、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令、其他不可抗力或第三方的不作为而造成的不能服务或延迟服务不承担责任。
            <br />
            无论在任何情况下（包括但不限于疏忽原因），由于使用晓黑板上的信息或由晓黑板软件及平台链接的信息，或其他与晓黑板软件及平台链接的网站信息，对您或他人所造成任何的损失或损害（包括直接、间接、特别或后果性的损失或损害，例如收入或利润之损失，智能终端系统之损坏或数据丢失等后果），均由使用者自行承担责任（包括但不限于疏忽责任）。
            <br />
            晓黑板所载的信息，包括但不限于文本、图片、数据、观点、网页或链接，虽然力图准确和详尽，但晓黑板并不就其所包含的信息和内容的准确、完整、充分和可靠性做任何承诺。晓黑板表明不对这些信息和内容的错误或遗漏承担责任，也不对这些信息和内容作出任何明示或默示的、包括但不限于没有侵犯第三方权利、质量和没有智能终端病毒的保证。
            <br />
            对于晓黑板所提及或展示的产品或服务，晓黑板仅提供信息。晓黑板不是相关产品的生产者或经销者，亦不是服务的提供方。晓黑板不就该等产品或服务做出任何声明或保证，所有展示的产品和服务应受其本公司或服务提供方所做质量承诺和条款的约束。
          </p>
          <h5>第三方链接</h5>
          <p>
            晓黑板可能保留有第三方网站或网址的链接，访问这些链接将由用户自己作出决定，晓黑板并不就这些链接上所提供的任何信息、数据、观点、图片、陈述或建议的准确性、完整性、充分性和可靠性提供承诺或保证。晓黑板没有审查过任何第三方网站，对这些网站及其内容不进行控制，也不负任何责任。如果您决定访问任何与本站链接的第三方网站，其可能带来的结果和风险全部由您自己承担。
          </p>
          <h5>适用法律和管辖权</h5>
          <p>
            通过访问晓黑板软件及平台或使用晓黑板提供的信息服务,
            即表示您同意该访问或服务受中华人民共和国法律的约束，且您同意受中华人民共和国法院的管辖。访问或接受服务过程中发生的争议应当协商解决，协商不成的，各方一致同意至上海晓信信息科技有限公司住所所在地有管辖权的法院诉讼解决。
          </p>
          <h5>收集资料的方法</h5>
          <p>
            晓黑板将会收集及储存您在晓黑板软件及平台输入的资料，或通过其他渠道向我们提供的信息。我们亦会从关联公司、商业伙伴及其他独立第三方资料来源，获取与您有关并合法收集的个人或非个人资料；
            <br />
            您在相关站点及其他供应商所作的隐私设定，可限制或阻止我们对有关资料的查阅；
            若您提供个人资料予我们，即表示您接受，晓黑板将在所需期限内留存您的资料以履行有关用途，您的资料将在遵守适用相关法律及规定的情况下收集。
          </p>
          <h5>收集个人资料可作以下用途</h5>
          <p>
            提供晓黑板所包含的相关服务；晓黑板旗下网站及APP等客户端管理；用户身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的服务的安全性；如果我们监测到您使用我们的服务用于欺诈或非法目的，我们将会相应采取措施停止服务；向您推送最新的市场信息及优惠方案；设计全新或改善目前所提供的产品及服务；服务内容个性化更新；协助执行法例、警方或其他政府或监管机构调查，以及遵守适用法律及规例所施行的规定，或其他向政府或监管机构承诺之义务；在收集之时所通知的其他用途；及与上述任何项目直接有关的其他用途。
          </p>
          <h5>可能向您发送的信息</h5>
          <p>
            为保证服务完成所必须的验证码；使用产品或服务时所必要的推送通知；当前服务费用优惠及减免信息；关于晓黑板产品或服务的新闻、特别优惠及促销活动消息。
          </p>
          <h5>信息安全及隐私保护措施</h5>
          <p>
            我们使用行业通行的安全技术和程序，来保护您的个人信息不被未经授权的访问、使用或泄露；为更好地提供服务升级产品，我们可能会将我们获得的数据提供给第三方用于分析统计。在提供服务的过程中，我们也会使用第三方的产品或服务。以上第三方企业或个人，只有在必须的情况下才会接触到用户信息；请注意，任何安全系统都存在可能的及未知的风险。
          </p>
          <h5>变更</h5>
          <p>
            随着晓黑板服务的进一步提升，隐私声明的内容会随时更新。更新后的隐私声明一旦在晓黑板软件及平台上公布即有效代替原来的隐私声明。我们鼓励您定期查看本页以了解我们对于隐私保护的最新操作。
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
