import React, {useState, useEffect} from "react";
import eventBus from "./utils/events";
import { setItem, getItem } from './utils/cache'
import './style/style.scss'

const withOfflineTip = (Cpt) => () => {
    const getStorageTipStatus = () => {
      const showTip = getItem('showOfflineTip')
      return showTip === undefined ? true : showTip;
    }
    const [showOfflineTip, setShowOfflineTip] = useState(getStorageTipStatus)  
    const [countDown, setCountDown] = useState(5);

    const closeOfflineTip = () => {
      setShowOfflineTip(false)
      setItem('showOfflineTip', false)
    }
  
    useEffect(() => {
      eventBus.addListener("showOfflinePage", showOfflinePageHandle);
      
      return () => {
        eventBus.addListener("showOfflinePage", showOfflinePageHandle);
      }
    });

    const showOfflinePageHandle = () => {
        setCountDown(0)
        setShowOfflineTip(true)
    }
  
    useEffect(() => {
      if(window?.location?.href?.includes('download')) {
        showOfflinePageHandle()
      }
    }, [])

    useEffect(() => {
      if (!showOfflineTip || getStorageTipStatus() === false) return;
  
      const timerHandle = () => {
        if(countDown === 0) return clearInterval(timer)
        setCountDown(countDown-1)
      }
      const timer = setInterval(timerHandle, 1000)
      return () => {
        clearInterval(timer)
      }
    }, [countDown, showOfflineTip])
  
    return (
      <>
        { showOfflineTip && 
          <div className='offline-tip'>
            <div className='tip-box'>
              <div className='tip-top'>
                <span className='tip-title'>晓黑板停服公告</span>
              </div>
              <div className='tip-content'>
                <div>尊敬的用户：</div>
                <p>您好！感谢您一直以来对晓黑板的信任与支持。由于业务调整，我们非常遗憾地通知您：自2022年7月15日起，晓黑板将停止注册新账号，已注册的账号可正常使用；2022年8月31日，晓黑板将正式停止运营与服务，晓黑板APP、网页版、PC客户端、数字校园管理后台届时将全部无法登陆使用。</p>
                <p>服务关闭后，除法律法规另有规定或晓黑板与您所签署的协议另有约定外，<span className='font-weight'>晓黑板平台所有帐号数据、内容等信息将会做清除处理，不会用于其他途径</span>。您可以根据需要在8月31日前自行完成相关内容、数据的下载与备份。</p>
                <p>晓黑板成立于2015年，始终致力于架起家校沟通数字桥梁、助力公立学校信息化，实现“让学校教育更高效，让家庭教育更科学”的使命。上线七年以来，我们陪伴和见证了万千家庭孩子的成长，也收获了无数老师、家长、孩子的喜爱。对此，我们感到荣幸之至，同时也深感遗憾与抱歉。</p>
                <p>最后，衷心感谢您对晓黑板的支持和信任！如有任何相关疑问，可于公众号「晓黑板」内留言咨询，或拨打客服热线400-021-3668。我们会竭力做好停止服务前最后的工作。感谢理解！</p>
                <p className='text-right'>晓黑板团队</p>
                <p className='text-right'>2022年6月20日</p>
              </div>
              <div className='tip-bottom'>
                <div className={`tip-close ${countDown ? 'countdown' : ''}`} onClick={countDown ? () => {}: closeOfflineTip} > 
                  <span>我知道了</span>
                  { countDown ? <span>{`（${countDown}s）`}</span> : null}
                </div>
              </div>
            </div>
          </div>
        }
        <Cpt />
      </>
    )
  }
export default withOfflineTip
