import React from 'react'
import { NavLink } from 'react-router-dom'
import Title from '../../../components/Title'
import Slider from 'react-slick'
import './future.scss'
import './future-media.scss'

const lists = [
  {
    id: 'teacher',
    src: require('../../../assets/main_teacher@2x.png'),
    name: '教师',
    sub: '助力教师提效减负',
  },
  {
    id: 'parent',
    src: require('../../../assets/main_parent@2x.png'),
    name: '家长',
    sub: '助力家长专注教育',
  },
  {
    id: 'student',
    src: require('../../../assets/main_student@2x.png'),
    name: '学生',
    sub: '助力学生全面发展',
  },
]

export default () => {
  const title = '晓黑板正在帮助学校开创中国教育的未来'
  return (
    <div className="future">
      <Title title={title} className={'mob-title'} lineName={'mob-line'} />
      <div className="mobile-content">
        <Slider
          infinite
          slidesToShow={1}
          centerMode
          variableWidth
          className="future-slider"
        >
          {lists.map((item, index) => (
            <div className="card-wrapper" key={index} style={{ width: 205 }}>
              <div className="content">
                <div className="model">
                  <div className="display">
                    <div className="top">
                      <img src={item.src} alt="" />
                    </div>
                    <div className="bottom">
                      <span className="title">{item.name}</span>
                      <p className="sub">{item.sub}</p>
                    </div>
                  </div>
                  <div className="link">
                    <NavLink className="link-to" to={`/features/${item.id}`}>
                      了解更多
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="fu-content">
        {lists.map((item) => (
          <div key={item.id} className="content">
            <div className="model">
              <div className="display">
                <div className="top">
                  <img src={item.src} alt="" />
                </div>
                <div className="bottom">
                  <span className="title">{item.name}</span>
                  <p className="sub">{item.sub}</p>
                </div>
              </div>
              <div className="link">
                <NavLink to={`/features/${item.id}`} className="tolink">
                  了解更多&nbsp;&gt;
                </NavLink>
              </div>
            </div>
            <div className="line"></div>
          </div>
        ))}
      </div>
    </div>
  )
}
