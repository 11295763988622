import React from 'react'
import Title from '../../../components/Title'
import './notice.scss'
import './notice-media.scss'

const lists = [
  {
    id: 1,
    icon: require('../../../assets/mob_system@2x.png'),
    name: '系统通知',
  },
  {
    id: 2,
    icon: require('../../../assets/mob_mess@2x.png'),
    name: '短信通知(免费)',
  },
  {
    id: 3,
    icon: require('../../../assets/mob_tel@2x.png'),
    name: '电话通知(免费)',
  },
]

export default () => {
  const title = '家校通知使命必达 反馈管理不留死角'
  return (
    <div className="notice">
      <Title title={title} className="mob-title" lineName="mob-line" />
      <p className="top-title">老师更省心，家长更放心</p>
      <div className="not-content">
        {lists.map((item) => (
          <div className="content" key={item.id}>
            <div className="icon">
              <img src={item.icon} alt="" />
            </div>
            <span className="name">{item.name}</span>
          </div>
        ))}
      </div>
      <div className="des">
        <div className="content">
          <span className="title">
            收到通知没有反馈的家长，老师可以通过以上手段一键搞定
          </span>
        </div>
      </div>
    </div>
  )
}
