import React from 'react'
import Title from '../../../components/Title'
import './worktime.scss'
import './worktime-media.scss'

export default ({ title, src, mobSrc, width }) => {
  return (
    <div className="worktime">
      <Title title={title} />
      <div className="content" style={{ width: `${width}px` }}>
        <div className="mob">
          <img className="mob_src" src={mobSrc} alt="" />
        </div>
        <img className="pc_src" src={src} alt="" />
      </div>
    </div>
  )
}
