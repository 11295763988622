/**
 * set item
 *  name
 *  value
 *  expires: 当天晚上24：00
 */
export function setItem(name, value) {
    if (!localStorage) {
        console.log('unsupport localStorage')
        return
    }

    let obj = {
        value: value,
        expires: new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000
    }
    localStorage.setItem(name, JSON.stringify(obj))
}

/**
 * get item
 *  name
 */
export function getItem(name) {
    let objStr = localStorage?.getItem(name)
    let obj
    try {
        obj = JSON.parse(objStr)
    } catch (e) {
        console.log('json parse error:', e)
    }

    if (!obj) return

    if (obj.expires && obj.expires < Date.now()) {
        localStorage.removeItem(name)
        return
    }

    return obj.value
}

/**
 * remove item
 */
export function removeItem(name) {
    if (!localStorage) {
        console.log('unsupport localStorage')
        return
    }

    localStorage.removeItem(name)
}
